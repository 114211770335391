<template>
    <div>
        <div class="contract-terms title">
            <ScrollBar :minHeight="350" :maxHeight="350">
                <div class="contract-text" ref="contractTermsDiv">
                    <div class="section">
                        <div class="text-center div-title">
                            <h5>CONTRATADA</h5>
                        </div>
                        <div class="address">
                            <strong>Razão Social:</strong> Nixweb Sistemas Ltda – Fantasia: Nixweb
                            <strong>CNPJ:</strong> 05315131000181
                            <strong>IE:</strong> Isenta
                            <strong>CEP:</strong> 06454-000
                            <strong>Endereço:</strong> Alameda Rio Negro, 503 - Sala 2020
                            <strong>Bairro:</strong> Alphaville Centro Industrial e Empresarial/Alphaville
                            <strong>Cidade:</strong> Barueri
                            <strong>UF:</strong> SP
                        </div>
                    </div>

                    <div class="section">
                        <div class="text-center div-title">
                            <h5>CONTRATANTE</h5>
                        </div>
                        <div class="address">
                            <strong>Razão Social:</strong> {{ company.personResponse.companyName }} – Fantasia: {{
                company.personResponse.tradeName }}
                            <strong>CNPJ:</strong> {{ company.personResponse.document }}
                            <strong>IE:</strong> {{ company.personResponse.stateInscription }}
                            <strong>CEP:</strong> {{ company.addressResponse.zipCode }}
                            <strong>Endereço:</strong> {{ company.addressResponse.street }}, {{
                company.addressResponse.number }}
                            <strong>Bairro:</strong> {{ company.addressResponse.province }}
                            <strong>Cidade:</strong>{{ company.addressResponse.city }}
                            <strong>UF:</strong> {{ company.addressResponse.state.id }}
                        </div>
                    </div>
                    <br>
                    <div class="section">
                        <p class="section-title"><b>1. </b>As partes acima qualificadas firmaram o <b>CONTRATO DE
                                ASSINATURA
                                DE
                                SOFTWARE</b> , na data de <b>{{ currentDate }}</b>. Considerando ter havido o interesse
                            recíproco,
                            entre os contratantes, de modificar o objeto
                            de
                            contratação, passando assim a ser considerada como nova realidade contratual, sobrepondo-se
                            ao
                            disposto nas cláusulas do contrato, o seguinte teor:.</p>
                    </div>
                    <br>
                    <div class="section">
                        <p class="section-title">Descrição: <b>
                                {{ term.description }}
                            </b>
                        </p>
                    </div>
                    <br>
                    <div class="section">
                        <p><b>2. </b> Pelo(s) serviço(s) a serem prestados, a CONTRATANTE deverá pagar à CONTRATADA
                            o
                            valor de <b>{{ term.value | currency }} <span v-if="term.monthly || term.name == 'User'">
                                    mês </span>
                            </b>.
                        </p>
                    </div>
                    <div class="section">
                        <p><b>3. </b> Ficam ratificadas todas as demais cláusulas e condições do instrumento particular
                            ora
                            alterado.
                        </p>
                    </div>
                    <br>
                    <div>
                        E por estarem assim justas e contratadas, assinam o presente em formato digital.
                    </div>
                </div>
            </ScrollBar>

        </div>
        <div v-if="!nfseIntegrado && name == 'taxDocument'">
            <Alert type="warning">
                <span>
                    Atenção, seu <b>
                        Município </b> não
                    é atendida para Nota Fiscal de Serviço, somente NFe de Remessa!
                </span>
            </Alert>
        </div>
        <div class="contract-action">
            <CheckboxSimple title="Eu aceito os termos e condições" v-model="accepted" />
        </div>
        <div class="text-center">
            <Button _key="save" title="Confirmar" type="primary" :clicked="save" :disabled="!accepted" size="medium" />
        </div>
    </div>

</template>

<script>

import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
    name: "Term",
    props: ["term"],
    components: {
        Button, CheckboxSimple, ScrollBar, Alert
    },
    data() {
        return {
            accepted: false,
            currentDate: new Date().toLocaleDateString(),
            urlConsultaMunicipio: "/api/v1/tax-document/nfse/consulta-municipio",
            urlGetById: "/api/v1/adm/company/get-by-id",
            urlCreate: "/api/v1/adm/company/contract-additive",
            company: {},
            nfseIntegrado: true,
        };
    },
    mounted() {
        this.getCompany();
        this.consultaMunicipio();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapActions("generic", ["getApi", "postApi"]),
        ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
        getCompany() {
            let params = {
                url: this.urlGetById,
                obj: { id: this.userLogged.companyId }
            };
            this.getApi(params).then((response) => {
                this.company = response.content;
            });
        },
        consultaMunicipio() {
            let params = {
                url: this.urlConsultaMunicipio,
            };
            this.getApi(params).then((response) => {
                this.nfseIntegrado = response.content;
            });
        },
        save() {
            const content = this.$refs.contractTermsDiv.innerHTML;
            let params = {
                url: this.urlCreate,
                obj: {
                    name: this.term.name,
                    ruleId: this.term.ruleId,
                    quantity: this.term.quantity,
                    term: content
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                this.hideModal();
                this.removeLoading(["save"]);
                this.addEvent({ name: "contractedPlan" });
            });
        },
    },
};
</script>

<style scoped>
.contract-terms {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 1rem auto;
}

.contract-text {
    margin-bottom: 1rem;
    padding-left: 5px;
    padding-right: 20px;
    text-align: justify;
}

.contract-action {
    margin-bottom: 1rem;
}

.div-title {
    padding: 10px;
}
</style>