<template>
    <div>
        <br />
        <div v-if="loading">
            <Loading type="line" :center="true" />
            <div class="title">Aguarde, estamos liberando os serviços contratados...</div>
        </div>
        <div v-else class="div-success">
            <Molded>
                <div class="text-center">
                    <div class="msg-success">Serviço contratado com sucesso!</div>
                    <div class="icon-success">
                        <i class="fa-light fa-circle-check"></i>
                    </div>
                    <div>
                        <Button _key="btnBack" type="info" title="Voltar" classIcon="fa-solid fa-arrow-left"
                            size="small" :clicked="back" />
                    </div>
                </div>
                <br />
            </Molded>
        </div>
        <div v-show="false">
            <Timer :initialTimer="4" :endTime="success" />
        </div>
    </div>
</template>

<script>

import { mapMutations } from "vuex";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";


export default {
    name: "Success",
    components: {
        Loading, Molded, Button, Timer
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        success() {
            this.loading = false;
        },
        back() {
            this.removeLoading(["btnBack"]);
            this.addEvent({ name: "backContractedPlan" });
        }
    }
}

</script>

<style scoped>
.div-success {
    width: 550px;
    margin: auto;
}

.msg-success {
    font-size: 18px;
}

.icon-success {
    font-size: 55px;
    color: #38a169;
    margin-top: 10px;
    margin-bottom: 15px;
}
</style>