<template>
    <div>
        <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
            :showButtons="panel.showButtons">
            <div slot="content-main">
                <div>
                    <br />
                    <Store />
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Store from '../../../components/modules/adm/store/Store.vue'

import { mapGetters, mapState, mapMutations } from "vuex";

export default {
    name: "StoreView",
    components: { Panel, Store },
    data() {
        return {
            panel: {
                module: "Adm",
                title: "Loja",
                showFilter: false,
                showSearch: false,
                showButtons: false,
            },
        };
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("user", ["hasModule"]),
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
    }
};
</script>